/*
Template Name: Appexy - Tailwind CSS Landing Page Template
Version: 1.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Mandali&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  @apply relative scroll-smooth;
}

body {
  font-size: 15px;
  @apply font-body overflow-x-hidden text-gray-700;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold;
}

.swiper-1 {
  @apply px-12;
}
.swiper-1 .swiper-slide {
  @apply relative transition-all duration-[0.3s] max-w-full overflow-hidden border border-gray-100 rounded-lg;
}
.swiper-1 .swiper-slide.swiper-slide-active {
  @apply scale-[1.1] shadow-[0px_5px_20px_0px_#343a401a];
}

.swiper-pagination-bullets-dynamic {
  @apply py-1;
}

.swiper-pagination-bullet-active {
  @apply ring-2 ring-offset-2 bg-primary ring-primary;
}

.header {
  @apply fixed top-0 inset-x-0 flex flex-wrap lg:justify-start lg:flex-nowrap items-center z-40 w-full transition-all text-sm py-3 lg:py-0 bg-transparent;
}
.header .navbar-nav {
  @apply py-5 transition-all duration-500 mt-5 lg:mt-0 ps-3;
}
.header .navbar-nav .nav-link {
  @apply relative font-medium text-base py-1.5 mx-1 lg:block transition-all;
}
.header .navbar-nav .nav-link::after {
  content: "";
  @apply transition-all absolute bottom-px inset-x-2.5 rounded-lg border-b-2 border-transparent;
}
.header .navbar-nav .nav-btn-link {
  @apply py-2 flex text-base font-medium;
}
.header .navbar-nav .nav-btn {
  @apply py-1.5 px-4 w-24 rounded flex justify-center items-center border text-base font-medium transition-all;
}
.header.header-dark .logo {
  @apply text-white;
}
.header.header-dark.nav-sticky .logo {
  @apply text-black;
}
.header.header-dark.nav-sticky .logo span {
  @apply text-emerald-500;
}
.header.header-light .navbar-brand .logo-light {
  @apply hidden lg:block;
}
.header.header-light .navbar-brand .logo-dark {
  @apply block lg:hidden;
}
.header.header-light .navbar-nav .nav-link {
  @apply text-gray-600 lg:text-gray-300 hover:text-white transition-all;
}
.header.header-light .navbar-nav .active .nav-link {
  @apply text-primary lg:text-white;
}
.header.header-light .navbar-nav .active .nav-link::after {
  @apply border-primary lg:border-white;
}
.header.header-light .navbar-nav .nav-btn-link {
  @apply text-gray-600 lg:text-white;
}
.header.header-light .navbar-nav .nav-btn {
  @apply border-primary/25 text-primary bg-primary/10 hover:bg-primary hover:text-white lg:bg-white/10 lg:hover:text-blue-600 lg:hover:bg-white lg:border-white/25 lg:text-white;
}
.header.header-dark .navbar-brand .logo-light, .header.nav-sticky .navbar-brand .logo-light {
  @apply hidden;
}
.header.header-dark .navbar-brand .logo-dark, .header.nav-sticky .navbar-brand .logo-dark {
  @apply block;
}
.header.header-dark .navbar-nav .nav-item .nav-link, .header.nav-sticky .navbar-nav .nav-item .nav-link {
  @apply text-gray-500;
}
.header.header-dark .navbar-nav .nav-item.active .nav-link, .header.nav-sticky .navbar-nav .nav-item.active .nav-link {
  @apply text-primary;
}
.header.header-dark .navbar-nav .nav-item.active .nav-link::after, .header.nav-sticky .navbar-nav .nav-item.active .nav-link::after {
  @apply border-primary;
}
.header.header-dark .navbar-nav .nav-btn-link, .header.nav-sticky .navbar-nav .nav-btn-link {
  @apply text-gray-500;
}
.header.header-dark .navbar-nav .nav-btn, .header.nav-sticky .navbar-nav .nav-btn {
  @apply border-primary/20 text-primary bg-primary/10 hover:bg-primary hover:text-white;
}
.header.nav-sticky {
  @apply bg-white shadow-md;
}
.header.nav-sticky .navbar-nav {
  @apply py-3.5;
}
.header.nav-sticky .navbar-nav .nav-item .nav-link {
  @apply text-gray-700;
}
.header.nav-sticky .navbar-nav .nav-btn-link {
  @apply text-gray-700;
}